import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {IntakeFormTemplate} from '../../../intake-form-template-index/intake-form-template';
import {Patient} from '../../../../health/doctor/patient';
import {PatientService} from '../../../../services/patient.service';
import {LoaderService} from '../../../../services/loader.service';
import {AssociateCarePlanWithChartService} from '../../todays-patients/associate-care-plan-with-chart.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {PatientIntakeFormService} from '../patient-intake-form.service';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-intake-form-report',
  templateUrl: './intake-form-report.component.html',
  styleUrls: ['./intake-form-report.component.scss']
})
export class IntakeFormReportComponent implements OnInit {
  @ViewChild('report', { static: false }) report: ElementRef | undefined;
  public intakeForm: IntakeFormTemplate = new IntakeFormTemplate();
  public patient: Patient;
  public isLoading: boolean;
  public is_added_to_chart: boolean;
  public intakeFormId: number;
  public patient_id: number;
  constructor( public injector: Injector, public patientService: PatientService, public loaderService: LoaderService,
               public carePlanWithChart:  AssociateCarePlanWithChartService,
               public globalErrorHandlerService: GlobalErrorHandlerService,
               public patientIntakeFormService: PatientIntakeFormService) {
  }

  ngOnInit(): void {
    this.intakeFormId  = this.injector.get('id', undefined);
    this.patient_id  = this.injector.get('patient_id', undefined);
    if (this.patient_id) {
      this.patientService.getPatient(this.patient_id).then(resp => this.patient = new Patient().load_from_json(resp));
    }
    if (this.intakeFormId) {
      this.getTemplate(this.intakeFormId);
    }
  }


  callAssociateWithMedicalChartMethod() {
    console.log('Associate with Start');
    this.isLoading = true;
    console.log('isLoading', this.isLoading);
    this.loaderService.show();

    // Offload the heavy task to a background thread using setTimeout
    setTimeout(() => {
      this.associateWithMedicalChart();
    }, 0);
  }

  async associateWithMedicalChart() {
    console.log('setParams-------');
    let date = new Date();
    let params = {
      fileType: 'document', title: this.intakeForm.name, description: 'Care Plan', template: this.report, patient_id: this.intakeForm.patient_id,
      attachable_type: 'Document', document_type: 'Patient Intake Form', attachable_id: 0, date: date,
      sourceable_type: 'IntakeTemplate', sourceable_id: this.intakeForm.id
    };
    console.log('Params-------', params);

    try {
      await this.carePlanWithChart.associateToMedicalChart(params);

      // Operation completed successfully
      this.isLoading = false;
      this.is_added_to_chart = true;
      this.updateFormStatus(this.intakeForm.id, 'added_to_chart');
      console.log('end of params-------');
    } catch (error) {
      // Handle errors from the associateToMedicalChart operation
      console.error('Error associating to medical chart:', error);
    }
  }

  updateFormStatus(form_id, status: string) {
    this.patientIntakeFormService.updatePatientFormStatus(form_id, status).then(resp => {
      this.patientIntakeFormService.reloadIntakeForms.next(1)
      this.intakeForm.load_from_json(resp);
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  generatePdf(action) {
    this.loaderService.show();
    this.report.nativeElement.style.height = this.report.nativeElement.style.offsetHeight;
    html2canvas(this.report.nativeElement, {
      width: this.report.nativeElement.offsetWidth,
      height: this.report.nativeElement.offsetHeight})
      .then((canvas) => {
        let imgData = canvas.toDataURL('image/png');
        let imgWidth = 210;
        let pageHeight = 295;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        let doc = new jsPDF('p', 'mm');
        let position = 0;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        if (action === 'print') {
          doc.autoPrint();
          window.open(doc.output('bloburl'), '_blank');
        } else {
          doc.save( 'file.pdf');
        }
        this.loaderService.hide();
      });
  }

  private getTemplate(id: number) {
    this.loaderService.show();
    this.patientIntakeFormService.getReportDetail(id).then(resp => {
      this.intakeForm = new IntakeFormTemplate().load_from_json(resp);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
    })

  }
}
