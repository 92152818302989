<div class="flex-control flex-column flex-height">
  <form (ngSubmit)="save()" #noteComponentForm="ngForm" class="flex-body p scroll-browser">
    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Component*</label>
      </div>
      <div class="col-md-4 col-sm-5">
<!--        <input type="text" class="form-control"-->
<!--               [(ngModel)]="noteComponent.component" name="component" required>-->

        <div class="input-group">
          <input [(ngModel)]="noteComponent.component" [typeaheadAsync]="true"
                 typeaheadWaitMs="500"
                 [typeahead]="data_source"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 autocomplete="off"
                 (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 [typeaheadOptionField]="'name'"
                 placeholder="Component"
                 name="component" class="form-control">
        </div>
      </div>
    </div>


    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Sub-Component-1</label>
      </div>
      <div class="col-md-4 col-sm-5">
<!--        <input type="text" class="form-control"-->
<!--               [(ngModel)]="noteComponent.sub_component_1" name="sub-component-1" >-->

        <div class="input-group">
          <input [(ngModel)]="noteComponent.sub_component_1" [typeaheadAsync]="true"
                 typeaheadWaitMs="500"
                 [typeahead]="sub_data_source"
                 (typeaheadLoading)="subComponentChangeTypeaheadLoading($event)"
                 autocomplete="off"
                 (typeaheadNoResults)="subComponentChangeTypeaheadNoResults($event)"
                 (typeaheadOnSelect)="subComponentTypeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 [typeaheadOptionField]="'name'"
                 placeholder="Sub Component 1"
                 name="sub-component-1" class="form-control">
        </div>

      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-2 col-sm-5">
        <label>Sub-Component-2</label>
      </div>
      <div class="col-md-2 col-sm-5">
        <input type="text" class="form-control"
               [(ngModel)]="noteComponent.sub_component_2" name="sub-component-2"  placeholder="Sub Component 2" >
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Head*</label>
      </div>
      <div class="col-md-2 col-sm-5">
        <select class="form-control" [(ngModel)]="noteComponent.head" name="head"  required>
          <option value=undefined selected disabled >Select Head</option>
          <option value="h" >H</option>
          <option value="h1" >H1</option>
          <option value="h2" >H2</option>
        </select>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Category*</label>
      </div>
      <div class="col-md-2 col-sm-5">
        <input type="text" class="form-control"
               [(ngModel)]="noteComponent.category" name="category"  placeholder="Category" required>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Library*</label>
      </div>
      <div class="col-md-2 col-sm-5">
        <select class="form-control" [(ngModel)]="noteComponent.library" name="library" required>
          <option value=undefined selected disabled >Select Library</option>
          <option value="snomed" >SnoMed</option>
          <option value="icd10" >ICD10</option>
          <option value="cpt4" >CPT4</option>
          <option value="medication" >Medication</option>
        </select>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Default Control*</label>
      </div>
      <div class="col-md-2 col-sm-5">
        <select class="form-control" [(ngModel)]="noteComponent.default_control" name="default_control" required>
          <option value=undefined selected disabled >Default Control</option>
          <option value="present/absent">Present/Absent</option>
          <option value="yes/no">Yes/No</option>
          <option value="normal/abnormal" >Normal/Abnormal</option>
          <option value="observation" >Observation</option>
        </select>
      </div>
    </div>





    <div class="row">
      <div class="col-sm-2"></div>
      <div class="col-sm-10">
        <button type="submit" class="btn btn-secondary btn-min mr" [disabled]="!noteComponentForm.form.valid" >{{noteComponent.id ? 'Update' : 'Create'}}</button>
      </div>
    </div>
  </form>
</div>
