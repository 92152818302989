<div class="flex-body scroll-browser">
  <table class="table table-hover">
    <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Specialty</th>
      <th>Author</th>
      <th>Owner</th>
      <th>Created on</th>
      <th>Last Modified By</th>
      <th>Last Modified on</th>
      <th>Status</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let template of _enote_service.templates_listing">
      <tr>
        <td><a (click)="add_enote(template, 'edit_temp')">{{template.name}}</a></td>
        <td >{{template.document_type_name}}</td>
        <td >{{template.specialty_name}}</td>
        <td >{{template.author_name}}</td>
        <td >{{template.owner_name}}</td>
        <td >{{template.created_at | date: 'medium'}}</td>
        <td >{{template.last_modified_by}}</td>
        <td >{{template.last_modified_at  | date: 'medium'}}</td>
        <td>{{template.shared_status}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <app-no-result-found *ngIf=" _enote_service.templates_listing &&  _enote_service.templates_listing.length == 0"></app-no-result-found>
  <div class="text-center" *ngIf=" _enote_service.totalTempaltes && _enote_service.totalTempaltes > 20 " >
    <pagination class="m0" [totalItems]="_enote_service.totalTempaltes" [maxSize]="20" [itemsPerPage]="20" (pageChanged)="pageChanged($event)"></pagination>
  </div>
</div>

