<div class="flex-header">
  <h4 class="m0">Patient Registration</h4>
</div>
<div class="flex-body p scroll-browser">
  <div class="row form-group">
    <div class="col-md-2 col-sm-3"><label class="field-label control-label">Patient</label></div>
    <div class="col-md-4 col-sm-5">
      <div class="input-group">
        <input class="form-control" [(ngModel)]="asyncSelected" [typeaheadAsync]="true"
               typeaheadWaitMs="500"
               [typeahead]="patient_data_source"
               (typeaheadLoading)="changePatientTypeaheadLoading($event)"
               autocomplete="off"
               (typeaheadNoResults)="changeTypeaheadNoResults($event)"
               (typeaheadOnSelect)="typeaheadOnPatientSelect($event)"
               [typeaheadOptionsLimit]="20"
               [typeaheadOptionField]="'name_age_gender_phone'"
               placeholder="Search by name, phone, email or ICD code"
               name="patients">
        <div class="input-group-addon" *ngIf="patientTypeaheadLoading"><i class="fa fa-spinner fa-spin"></i></div>
      </div>
      <div *ngIf="typeaheadNoResults" class="text-danger fs13">No Results Found!</div>
    </div>
    <div class="col-md-1 col-sm-2">
      <button type="button" class="btn btn-secondary" (click)="classicModal.show()"><i class="fa fa-plus"></i>&nbsp; Add</button>
    </div>
  </div>
  <ng-container *ngIf="patient && patient.id">
    <div class="row form-group">
      <div class="col-md-2 pt-sm"><label class="field-label control-label">Selected Patient</label></div>
      <div class="col-md-5 col-sm-7">
        <div class="white-container-box p-lg">
          <div class="media">
            <div class="media-left"><img class="img-rounded thumbnail thumb64" [src]="patient.patient_profile_pic" alt="Image"/></div>
            <div class="media-right">
              <h4 class="m0 mb-sm">{{patient.name}}</h4>
              <ng-container *ngIf="patient.mobile_number_value">
                <h5 class="m0 mb-sm"><i class="fa icon-phone"></i>&nbsp;{{patient.mobile_number_value  | mask: ' (000) 000-0000'}}</h5>
              </ng-container>
              <h5 class="m0 mb-sm"><i class="fa icon-user"></i>&nbsp; {{userService.removeUnderScore(patient.customize_age)}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-2 col-sm-3"><label class="field-label control-label">Program Type*</label></div>
      <div class="col-md-6 col-sm-5">
        <div class="row">
          <label class="col-md-3 col-sm-5 text-uppercase" *ngFor="let type of program?.programTypes; let i = index">
            <input [id]="type" type="checkbox" [value]="type" [name]="type" (change)="setTypeButtonCall($event)" [checked]="selectedType(type)"/>
            {{userService.removeUnderScore(type)}}
          </label>
        </div>
      </div>
    </div>

    <div class="row form-group" *ngIf="programEnrollment.enrollment_types?.includes('ccm')">
      <div class="col-md-2 col-sm-3"><label class="field-label control-label">CCM Severity*</label></div>
      <div class="col-md-6 col-sm-5">
        <label class="fw-500 mr-lg">
          <input type="radio" class="ml" name="complex" value="complex" [(ngModel)]="programEnrollment.severity"/>
          Complex
        </label>
        <label class="fw-500 mr-lg">
          <input type="radio" class="ml" name="non_complex" value="non_complex" [(ngModel)]="programEnrollment.severity"/>
          Non-Complex
        </label>
      </div>
    </div>

    <ng-container *ngIf="programEnrollment.enrollment_types?.includes('tcm')">
      <div class="row form-group">
        <div class="col-md-2 col-sm-3"><label class="field-label control-label">Discharge Date*</label></div>
        <div class="col-md-3 col-sm-5">
          <input type="text" placeholder="Discharge Date*" name="discharge_date_p"
                 [(ngModel)]="programEnrollment.tcm_discharge_date"
                 class="form-control"
                 bsDatepicker
                 [bsValue]="bsValue"
                 [maxDate]="maxDate"
                 required
                 [bsConfig]="{ customTodayClass: 'today-date-select',
                         dateInputFormat: 'YYYY-MM-DD', showWeekNumbers:false, adaptivePosition: true}">
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 col-sm-3"><label class="field-label control-label">Decision Making*</label></div>
        <div class="col-md-6 col-sm-5">
          <label class="fw-500 mr-lg">
            <input type="radio" class="ml" name="tcm_moderate" value="tcm_moderate" [(ngModel)]="programEnrollment.tcm_decision" required/>
            Moderate
          </label>
          <label class="fw-500 mr-lg">
            <input type="radio" class="ml" name="tcm_complex" value="tcm_complex" [(ngModel)]="programEnrollment.tcm_decision" required/>
            Complex
          </label>
        </div>
      </div>

    </ng-container>

    <div class="mb-lg">
      <p *ngIf="loaderService.uploading_in_process" class="fs12"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...</p>
      <button class="btn btn-secondary btn-min" type="submit" (click)="save()"
              [disabled]="disabledSaveButton()"
              *ngIf="!loaderService.uploading_in_process">
        Save
      </button>
    </div>
    <app-econtact [patient]="patient" *ngIf="patient && patient.id"></app-econtact>
    <div>
      <button class="btn btn-tertiary btn-outline"
              (click)="continue()"
              [disabled]="!programService.currentProgramEnrollmentObject.id">
        Continue
      </button>
    </div>
  </ng-container>
</div>
<div class="modal modal_popup" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <app-add-new-patient (closeModel)="classicModal.hide()" (new_user)="handle_user($event);classicModal.hide();"></app-add-new-patient>
</div>
