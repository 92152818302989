import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {PatientService} from '../../services/patient.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-patient-intake',
  templateUrl: './patient-intake.component.html',
  styleUrls: ['./patient-intake.component.scss']
})
export class PatientIntakeComponent implements OnInit {
  user_id: any;
  intake_id: any;
  public showMessage: boolean;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public userService: UserService,
              public globalErrorHandlerService: GlobalErrorHandlerService,
              public loaderService: LoaderService,
              public patientService: PatientService) {
  }
  ngOnInit(): void {
    this.route.queryParams
      .subscribe((params: Params) => {
        this.user_id = params['user_id'];
        this.intake_id = params['intake_id'];

        this.getPatientIntakeFormStatus()

      })
  }

  getPatientIntakeFormStatus() {
    this.patientService.getPatientIntakeFormStatus(this.user_id, this.intake_id).then(resp => {
      if (resp['status'] == 'link_expired') {
        this.showMessage = true;
        return;
      }
      if (resp['status'] == 'show_login') {
        if(this.userService.current_user)
        {
          const id = this.userService.current_user.profileable_id
          this.router.navigate([`/patients/${id}`], {replaceUrl: true });
          return;
        }
        else
        {
          this.router.navigate(['/'], { queryParams: { show_login: 'true' }, replaceUrl: true });
          return;
        }
      }
      if (resp['status'] == 'reset_password') {
        const hashid = this.user_id;
        this.router.navigate(['/set-password'], { queryParams: { id: hashid }, replaceUrl: true });
        return;
      }
    }).catch(error => {
      console.error('Failed to load pending forms:', error);
      this.globalErrorHandlerService = error.error.error;
    });
  }
}
