import { Injectable }     from '@angular/core';
import {  HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Patient } from '.././health/doctor/patient';
import {UserService} from './user.service';
import {CustomHttpClient} from './custom-http-client.service';
import {nullRemover} from './nullRemover';
import {Router} from '@angular/router';
import {GlobalErrorHandlerService} from './global-error.service';
import {Appointment} from '../health/models/appointment';
import {Econtact} from '../health/doctor/econtact';
import {PatientInsurance} from '../health/models/patientInsurance';
import {Term} from '../enote/enote_models/term';


@Injectable()
export class PatientService {
  appointments: Appointment[];
  public call_for_appointments = true;
  public relationships: string[] = ['Father', 'Mother', 'Sister', 'Brother', 'Spouse', 'Child', 'Legal Guardian', 'Primary Care Physician'];
  public insurance_types: string[] = ['medicare', 'commercial', 'HMO', 'managed_medicaid', 'managed_medicare', 'medicaid', 'other', 'other_managed_care', 'PPO', 'self_pay'];
  public patient_relationships: any = [];
  public patient_providers: any = [];
  public providers: any = ['referring provider', 'rendering provider', 'primary care provider'];
  public insurance_importance_types: string[] = ['primary',  'secondary', 'tertiary'];
  constructor(private _tokenService: CustomHttpClient, private user_service: UserService, public _router: Router, public globalErrorHandlerService: GlobalErrorHandlerService) {}

  save(patient: Patient, redirect_to?): Promise<any> {
    if (patient.is_new_record()) {
      return this.create(patient, redirect_to);
    }
    else {
      return this.update(patient, redirect_to);
    }
  }
  update(patient: Patient, redirect_to?) {
    let password = patient.user.password;
     return this._tokenService
      .put(patient.get_url(), patient.to_params())
      .toPromise()
       .then(response => {
         patient.load_from_json(response);
         if (!this.user_service.current_user) {
           this.user_service.login(patient.user.email, password, redirect_to);
         }
         return response;
       }).then(res => res)
  }

  create(patient: Patient, redirect_to?): Promise<any> {
    // saving patient reference for login when user is not signup
    let password = patient.user.password;
    return this._tokenService
      .post('patients/', patient.to_params())
      .toPromise()
      .then(response => {
        patient.load_from_json(response);
        if (!this.user_service.current_user) {
          patient.load_from_json(response);
          return this.user_service.login(patient.user.email, password, redirect_to).then(res => {
            this.user_service.patient_to_verify = patient;
            return patient;
          });
        } else {
          return response;
        }
      })
  }

  removeParentId(id: any): Promise<any> {
    return this._tokenService.put('patients/remove_parent/' + id, {})
      .toPromise()
      .then(response => response);
  }

  check_duplicate(data) {
    return this._tokenService
      .post('patients/check_duplicate/', data)
      .toPromise()
      .then(response => {
        return response
      })
  }

  getPatient(id: number): Promise<any[]> {
    return this._tokenService.get('patients/' + id)
      .toPromise()
      .then(response => response);
  }
  getPatientWithVital(id: any): Promise<any[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    return this._tokenService.get('patients/patient_with_vital/', {params: params})
      .toPromise()
      .then(response => response);
  }
  selected_patient(id: any): Promise<any[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    return this._tokenService.get('patients/get_patient', {params: params})
      .toPromise()
      .then(response => response);
  }
  getPatientInsurances(patient_id) {
    let params: HttpParams = new HttpParams();
    params = params.set('patient_id', patient_id);
    return this._tokenService.get('insurance/patient_insurances', {params: params})
      .toPromise()
      .then(response => response);
  }

  deletePatientInsurance(id: any) {
    return this._tokenService
      .delete('insurance?id=' + id)
      .toPromise()
      .then(response => response)
  }

  addPatientInsurance(insurance: PatientInsurance): Promise<any> {
    return this._tokenService
      .post('insurance', insurance.to_params())
      .toPromise()
      .then(response => response)
  }

  savePatientInsurance(insurance: PatientInsurance): Promise<any> {
    if (insurance.is_new_record()) {
      return this.addPatientInsurance(insurance);
    }
    else {
      return this.editPatientInsurance(insurance);
    }
  }

  editPatientInsurance(insurance: PatientInsurance) {
    return this._tokenService.put('insurance/' + insurance.id, insurance.to_params())
      .toPromise()
      .then(response => response);
  }

  public_search_insurance(term: string): Observable<any> {
    return this._tokenService
      .get('lead_integration/get_insurances/?term=' + term)
      .map((r) => r )
      .catch(error => {
        console.log(error);
        return Observable.throw(error);
      });
  }

  sendOtp(params: Object): Promise<any> {
    let endpoint = 'patients/send_otp';
    if (params['type'] == 'email') {
      endpoint = 'patients/send_email_otp'
    }
    return this._tokenService.post(endpoint, params)
      .toPromise()
      .then(response => response);
  }

  sendOtpForPatientAuthorization(params: Object): Promise<any> {
    let endpoint = 'patients/send_otp_for_patient_authorization';
    return this._tokenService.post(endpoint, params)
      .toPromise()
      .then(response => response);
  }
  sendMobileOtp(params: Object): Promise<any> {
    let endpoint = 'patients/send_mobile_otp';
    return this._tokenService.post(endpoint, params)
      .toPromise()
      .then(response => response);
  }

  sendOtpForMRN(params: Object) {
    return this._tokenService.post('patients/send_otp_mrn', params)
      .toPromise()
      .then(response => response);
  }

  verifyOtpMRN(params: Object) {
    return this._tokenService.post('patients/verify_otp_mrn', params)
      .toPromise()
      .then(response => response);
  }

  verifyOtp(params: Object): Promise<any> {
    return this._tokenService.post('patients/verify_otp', params)
      .toPromise()
      .then(response => response);
  }
  markVerified(patient_id: number): Promise<any> {
    return this._tokenService.post('patients/mark_verified', {patient_id: patient_id})
      .toPromise()
      .then(response => response);
  }

  verifyPatientAuthorizationOtp(params: Object): Promise<any> {
    return this._tokenService.post('patients/verify_patient_authorization_otp', params)
      .toPromise()
      .then(response => response);
  }
  verifyPatientMotherName(params: Object): Promise<any> {
    return this._tokenService.post('patients/verify_patient_mother_name', params)
      .toPromise()
      .then(response => response);
  }
  verifyMobileOtp(params: Object): Promise<any> {
    return this._tokenService.post('patients/verify_mobile_otp', params)
      .toPromise()
      .then(response => response);
  }

  verifyEmail(params: Object): Promise<any> {
    return this._tokenService.post('patients/verify_email_otp', params)
      .toPromise()
      .then(response => response);
  }

  patient_appointments(patient: Patient) {
    let args = new HttpParams();
    args = args.set('id', patient.id.toString());
    return this._tokenService.get('patients/appointments', {params: args})
      .toPromise()
      .then(response => response);
  }
  patient_appointments_with_pagination(patient: Patient, current_page : string) {
    let args = new HttpParams();
    args = args.set('id', patient.id.toString());
    args = args.set('page', current_page);
    return this._tokenService.getWithHeaders('patients/appointments', {observe: 'response' , params: args, responseType: 'json' })
      .map(response => {
      return {
        appointments: response.body,
        total: response.headers.get('X-Total'),
        per_page: response.headers.get('X-Per-Page'),
        current_page: response.headers.get('X-Page'),
        total_pages: response.headers.get('X-Total-Pages'),
        previous_page: response.headers.get('X-Prev-Page')
      }
    })
  }
  getPatientsAsObserevable(token: any): Observable<any> {
    return this._tokenService
      .get('patients/?token=' + token).share()
      .map(r => r);
  }
  searchPatient(token: any): Observable<any> {
    return this._tokenService
      .get('patients/search_patient/?token=' + token).share()
      .map(r => r);
  }
  getPatients(filter: any): Observable<any> {
    if (filter['page'] == null) {
      filter['page'] = 1
    }
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService
      .getWithHeaders('patients', {observe: 'response', params: params, responseType: 'json'}).share()
      .map(r => r)
      .catch(resp => {
        if (resp.status === 403) {
          this.globalErrorHandlerService.error = 'You are not authorized to perform this function.'
          this._router.navigateByUrl('enterprise/home/dashboard');
        }
        return Observable.throw(resp);
      });
  }
  getPatientAsObservable(token) {
    let query = new RegExp(token, 'ig');
    return this._tokenService
      .get('patients/get_patients/?name=' + token).share()
      .map(r => r as string[]);
  }
  searchPatients(token) {
    let query = new RegExp(token, 'ig');
    return this._tokenService
      .get('patients/search/?name=' + token).share()
      .map(r => r as string[]);
  }
  checkMRN(params) {
    return this._tokenService.post('patients/check_mrn', params)
      .toPromise()
      .then(response => response);
  }

  editPatientEmergencyContactNumber(econtact: Econtact): Promise<any> {
    return this._tokenService.put('patients/update_contact/' + econtact.id, econtact.to_params())
      .toPromise()
      .then(response => response);
  }

  savePatientEmergencyContactNumber(econtact: Econtact): Promise<any> {
    return this._tokenService
      .post('patients/contact', econtact.to_params())
      .toPromise()
      .then(response => response)
  }
  savePatientEcontact(econtact: Econtact): Promise<any> {
    if (econtact.is_new_record()) {
      return this.savePatientEmergencyContactNumber(econtact);
    }
    else {
      return this.editPatientEmergencyContactNumber(econtact);
    }
  }

  getPatientEmergencyContacts(id) {
    return this._tokenService.get('patients/contacts?id=' + id)
      .toPromise()
      .then(response => response)
  }
  getPatientEnrolledPrograms(id: any): Promise<any[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    return this._tokenService.get('enrolments/patient_enrolments', {params: params})
      .toPromise()
      .then(response => response);
  }
  sendCallRequestToProgramCareTeam(params: any): Promise<any> {
    return this._tokenService
      .post('enrolments/call_alert', params)
      .toPromise()
      .then(response => response)
  }

  sendOptOutRequestToProgramCareTeam(id: any) {
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    return this._tokenService.post('enrolments/opt_out_alert',  params)
      .toPromise()
      .then(response => response);
  }

  deleteEContact(id: any) {
    return this._tokenService
      .delete('patients/contact?id=' + id)
      .toPromise()
      .then(response => response)
  }

  getPatientRelationships() {
    return this._tokenService.get('patients/relationships')
      .toPromise()
      .then(response => response)
  }

  patientPortalActivateMail(patient_id: number, practice_id: number) {
    return this._tokenService
      .post('patients/portal_activate_mail', {patient_id: patient_id, practice_id: practice_id})
      .toPromise()
      .then(resp => resp)
  }

  getPatientPendingIntakeForms(id: number) {
    return this._tokenService.get('intake_forms/pending_intakes?patient_id=' + id)
      .toPromise()
      .then(response => response)
  }

  getPatientIntakeFormStatus(user_id: any, intake_id: any) {
    let params: HttpParams = new HttpParams();
    params = params.set('user_id', user_id);
    params = params.set('intake_id', intake_id);
    return this._tokenService.get('sent_intakes/screen_to_open' , {params: params})
      .toPromise()
      .then(response => response)
  }
}
