<div class="intake-dropdown" dropdown [insideClick]="true">
  <button #buttoncustomhtml id="button-custom-html-{{inputId}}" dropdownToggle type="button" class="btn btn-secondary dropdown-toggle"
          aria-controls="dropdown-custom-html">Send Intake Form <span class="caret"></span>
  </button>
  <div id="dropdown-custom-html-{{inputId}}" *dropdownMenu class="dropdown-menu animated slideInDown">
    <div class="richText-template">
      <input class="form-control" placeholder="Search Form Template" id="intakeTypeAhead-{{inputId}}" name="serchFormTemplate-{{inputId}}"
             [(ngModel)]="template_form_token" [typeahead]="data_source_template_form"
             typeaheadWaitMs="500" (typeaheadLoading)="changeTypeaheadLoading($event)"
             [typeaheadAsync]="true"
             (typeaheadNoResults)="changeTypeaheadNoResults($event)"
             (typeaheadOnSelect)="typeaheadFromTemplateOnSelect($event)" [typeaheadOptionsLimit]="7"
             [typeaheadOptionField]="'name'"/>
      <i class="fa fa-spinner fa-spin" *ngIf="typeaheadLoading"></i>
    </div>
    <div *ngIf="typeaheadNoResults" class="text-danger fs12 pt-sm">No Results Found!</div>
  </div>
</div>

<div class="modal fade" bsModal #emailConfirmation="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:200px; width:500px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="emailConfirmation.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Send Intake Form</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-warning p m0 text-center">“Are you sure your want to send <b class="text-capitalize" *ngIf="selectedTemplateName"> "{{selectedTemplateName}}" </b> to be filled up?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" *ngIf="selectedTemplateId" (click)="sendIntakeFormEmail(selectedTemplateId);emailConfirmation.hide();">Yes</button>
        <button type="submit" class="btn btn-inverse" (click)="emailConfirmation.hide();selectedTemplateId = ''">No</button>
      </div>
    </div>
  </div>
</div>
