<div class="flex-control flex-column" *ngIf="patient">
  <div class="flex-header">
    <div class="container text-center">
          <button *ngIf="isLoading; else notLoading" class="btn btn-default">
            <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...
          </button>
          <ng-template #notLoading>
            <ng-container *ngIf="intakeForm.status !== 'added_to_chart'">
              <button class="btn btn-secondary btn-outline btn-min mr-sm" *ngIf="!isLoading"
                      (click)="callAssociateWithMedicalChartMethod()" [disabled]="is_added_to_chart || isLoading">
                Add to Chart
              </button>
              <button class="btn btn-secondary btn-min mr-sm" (click)="generatePdf('print')">Print</button>
              <button class="btn btn-tertiary btn-min btn-outline" (click)="generatePdf('save')">Download</button>

            </ng-container>
          </ng-template>
    </div>
  </div>
  <div class="flex-body scroll-browser">
    <div #report class="container p">
        <div class="container text-center text-capitalize">
              <h3 class="m0 mt-sm" *ngIf="intakeForm.name">{{intakeForm.name}}</h3>
              <h4 class="m0 mt-sm" *ngIf="intakeForm.patient_name">( {{intakeForm.patient_name}} )</h4>
        </div>
      <ng-container *ngFor="let step of intakeForm.steps">
        <ng-container *ngIf="step.status === 'done'">
          <h3 class="m0 mb">{{step.name}}</h3>
          <app-demographics class="flex-body" *ngIf="step.name ==  'Demographics' && patient.id" [patient]="patient" [intakeReport]="true"></app-demographics>
          <app-medication-summaries class="flex-body" *ngIf="step.name == 'Current Medications'"
                                    [patient]="patient"
                                    [intakeForm]="true" [summary_of]="'medications'" [viewOnly]="true"></app-medication-summaries>
          <app-medication-summaries  class="flex-body" *ngIf="step.name ==  'Current Allergies'"
                                     [patient]="patient"
                                     [intakeForm]="true" [summary_of]="'allergies'" [viewOnly]="true"></app-medication-summaries>
          <app-medication-summaries  class="flex-body" *ngIf="step.name ==  'Current Diagnosis'"
                                     [patient]="patient"
                                     [intakeForm]="true" [summary_of]="'problems'" [viewOnly]="true"></app-medication-summaries>
          <ng-container class="flex-body" *ngIf="step.is_enote_step" >
            <ng-container *ngIf="step?.template && step?.template?.terms">
              <ng-container *ngFor="let term of step?.template?.terms; let index=index">
                <ng-container *ngIf="term">
                  <div contenteditable="false" [id]="'whole-wrapper-'+term.id">
                    <div contenteditable="false">
                      <app-print-enote [term]="term" [term_index]="index" class="mb-lg"
                                       [ngClass]="term.isComponentTypeChild() ? 'flex-component' : '' "></app-print-enote>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>



        </ng-container>

      </ng-container>
    </div>
  </div>
</div>
