<div class="flex-header filters-sec">
  <div class="row">
    <div class="col-xs-8"><h4 class="fs16">Patient Consent</h4></div>
    <div class="col-xs-4 text-right">
      <a (click)="choose_file()" type="file" class="btn btn-default"
         *ngIf="!loaderService.uploading_in_process && programEnrollment?.id"><i class="fa fa-file-o fa-fw"></i> Choose
        File</a>
      <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i
        class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
    </div>
  </div>
</div>
<div class="flex-body p scroll-browser">
  <ng-container *ngFor="let consent of consents; let i = index">
    <h4 class="fs30 text-center mb-lg mt-lg">{{ programEnrollment.getFullProgramName(consent.programme_type) }}</h4>
    <app-enrollment-consent class="mb-lg mt-lg" [consent]="consent" [programEnrollmentId]="programEnrollment.id"
                            [isEnrolledPatient]="isEnrolledPatient"></app-enrollment-consent>
  </ng-container>

  <div class="row mt-lg" *ngIf="programAttachmentsArray.length > 0">
    <div class="col-md-2 col-sm-3"><label class="field-label control-label">File Attachments</label></div>
    <div class="col-xs-6">
      <ul class="list-unstyled border-list attachment-box">
        <li class="list p-relative border-box shadow" *ngFor="let attachment of programAttachmentsArray">
          <h5 class="m0"><a [href]="attachment.document_url" target="_blank">{{ attachment.title }}</a></h5>
          <p class="m0">{{ attachment.creator_name }}</p>
          <div class="btn-hover-right">
            <a class="btn btn-danger btn-outline btn-sm" (click)="removeAttachment(attachment)">
              <i class="fa icon-trash"></i>
            </a>
          </div>
        </li>
      </ul>
      <div class="row hidden">
        <div class="col-md-4 col-sm-4" *ngFor="let attachment of programAttachmentsArray">
          <div class="white-container-box media p-sm mb-lg">
            <div class="media-body media-middle">
              <h4 class="m0" style="display:flex;">
                <a class="text-truncate" [href]="attachment.document_url" target="_blank">{{ attachment.title }}</a>
              </h4>
              <h5 class="mb0">{{ attachment.creator_name }}</h5>
            </div>
            <div class="media-right media-middle">
              <a class="btn btn-danger btn-outline btn-sm" (click)="removeAttachment(attachment)"><i
                class="fa fa-trash"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 col-sm-5 mt-lg">
      <button type="submit" *ngIf="!isEnrolledPatient" (click)="continue()"
              class="btn btn-tertiary btn-outline mt-lg mb-lg"
              [disabled]="!disabledButton()">Continue
      </button>
    </div>
  </div>
</div>


<app-attachments [auto_upload]="false" (document_uploaded)="handle_image_upload($event)"
                 [fileType]="'document'" [only_modal]="true"
                 [attachable_id]="0"
                 [attachable_type]="'Document'" [show]="show_attachment_popup">
</app-attachments>
