import { Injectable } from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from '../../../services/nullRemover';
import {CustomHttpClient} from '../../../services/custom-http-client.service';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientIntakeFormService {
  reloadIntakeForms: Subject<any> = new Subject<any>();

  constructor(private _tokenService: CustomHttpClient) { }

  getPatientIntakeForms(filter: Object): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('intake_forms/patient_forms', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          patientIntakeForms: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  updatePatientFormStatus(form_id, status: string) {
    return this._tokenService.put('intake_forms/form_status', {form_id: form_id, status: status})
      .toPromise()
      .then(response => response);

  }
  updateStepStatus(step_id, status?: string, templateId?: any) {
    let parm = {step_id: step_id, status: status, enote_template_id: templateId};
    return this._tokenService.put('intake_forms/step_status', nullRemover(parm) )
      .toPromise()
      .then(response => response);

  }
  getTemplate(id: number): Promise<any[]> {
    return this._tokenService.get('intake_forms/' + id)
      .toPromise()
      .then(response => response);
  }

  getReportDetail(id: number): Promise<any[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('form_id', id);
    return this._tokenService.get('intake_forms/report_detail' , {params: params})
      .toPromise()
      .then(response => response);
  }
}
