import {Component, Input, OnInit, Output, EventEmitter, Injector} from '@angular/core';
import {Patient} from '../../doctor/patient';
import {UserService} from '../../../services/user.service';
import {IntakeFormTemplate, IntakeFromStep} from '../../../routes/intake-form-template-index/intake-form-template';
import {PatientIntakeFormService} from '../../../routes/practice-site/patient-intake-form-index/patient-intake-form.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LoaderService} from '../../../services/loader.service';
import {timer} from 'rxjs';
import {EnoteComponent} from '../../../enote/enote/enote.component';
import {EnoteService} from '../../../services/enote.service';
import {Subscription} from 'rxjs/Subscription';
import {Template} from '../../../enote/enote_models/template';
import {PatientService} from '../../../services/patient.service';
import {TabsService} from '../../../services/tabs.service';

@Component({
  selector: 'app-patient-intake-workflow',
  templateUrl: './patient-intake-workflow.component.html',
  styleUrls: ['./patient-intake-workflow.component.scss']
})
export class PatientIntakeWorkflowComponent implements OnInit {
  public selectedStepIndex: number = 0;
  public selectedStep: IntakeFromStep = new IntakeFromStep();
  @Input() patient: Patient;
  @Input() pendingForm: IntakeFormTemplate;
  @Output() formCompleted : EventEmitter<void> = new EventEmitter<void>();
  public selected_stage_id: any;
  public formFillSuccessMessage: string = '';
  custom_injector: Injector;
  enoteComponent: any = EnoteComponent;
  showEnote: boolean;
  public saveIntakeTemplateSubscription: Subscription;
  public isPracticeUser: boolean;
  show: boolean;
  pendingFormId: number;

  constructor(public _userService: UserService, public patientIntakeFormService: PatientIntakeFormService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public loaderService: LoaderService,
              private injector: Injector, public enoteService: EnoteService, public patientService: PatientService,
              public tabsService: TabsService) {
    this.saveIntakeTemplateSubscription = this.enoteService.reloadEnotes.subscribe(item => {
      if (item) {
        this.updateStepStatus(this.selectedStep.id, 'done', item)
      }
    });
  }
  ngOnInit(): void {
    this.pendingForm  = this.injector.get('intakeForm', this.pendingForm);
    this.isPracticeUser = this.injector.get('isPracticeUser', false);
    this.pendingFormId = this.injector.get('id', this.pendingForm.id);
    if (this.isPracticeUser) {
      this.getTemplate(this.pendingFormId);
      this.getPatient(this.pendingForm.patient_id);
    } else {
      this.initializeFields();
    }
  }

  initializeFields() {
    this.selectedStepIndex = this.pendingForm.pending_index_number;
    this.selectedStep = this.pendingForm.steps[this.selectedStepIndex];
    this.selected_stage_id = this.selectedStep.is_enote_step ? 'enote' : this.selectedStep.name;
    if (this.selectedStep.is_enote_step) {
      if (this.selectedStep.enote_template_id) {
        this.setSelectedTemplate(this.selectedStep.enote_template_id);
        /* this.setCustomInjector(this.selectedStep.enote_template_id);*/
        //this.showEnote = true;
      }
    }
    this.updateFormStatus(this.pendingForm.id, 'being_filled');
    this.show = true;
  }

  onSelect(stageIndex: number, step: IntakeFromStep) {
    this.showEnote = false;
    this.selected_stage_id = step.is_enote_step ? 'enote' : step.name;
    this.selectedStepIndex = stageIndex;
    this.selectedStep = this.pendingForm.steps[this.selectedStepIndex];
    if (step.is_enote_step) {
      if (step.enote_template_id) {
        this.setSelectedTemplate(step.enote_template_id);
        //this.setCustomInjector(step.enote_template_id);
        //this.showEnote = true;
      }
    }

  }

  onNext() {
    this.updateStepStatus(this.selectedStep.id, 'done');
  }

  onSkip() {
    this.updateStepStatus(this.selectedStep.id, 'skipped');
  }

  updateFormStatus(form_id, status: string) {
    this.patientIntakeFormService.updatePatientFormStatus(form_id, status).then(resp => {
      this.pendingForm.load_from_json(resp);
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  updateStepStatus(stepId: number, status: string, templateId?: any) {
    this.loaderService.small_loder_show();
    this.patientIntakeFormService.updateStepStatus(stepId, status, templateId).then(resp => {
      const updatedStep = resp.step;
      const foundStep = this.pendingForm.steps.find(step => step.id === updatedStep.id);
      if (foundStep) {
        foundStep.load_from_json(updatedStep);
      }
      this.moveToNextStep();
      this.loaderService.small_loder_hide();
    }).catch(error => {
      this.loaderService.small_loder_hide();
      this.globalErrorHandlerService.error = error.error.error;
    })
  }

  moveToNextStep() {
    this.showEnote = false;
    if (this.selectedStepIndex + 1 < this.pendingForm.steps.length) {
      this.selectedStepIndex++;
      this.selectedStep = this.pendingForm.steps[this.selectedStepIndex];
      this.selected_stage_id = this.selectedStep.is_enote_step ? 'enote' : this.selectedStep.name;
      if (this.selectedStep.is_enote_step) {
        if (this.selectedStep.enote_template_id) {
          this.setSelectedTemplate(this.selectedStep.enote_template_id);
          //this.setCustomInjector(this.selectedStep.enote_template_id);
          //this.showEnote = true;
        }
      }
    } else {
      this.updateFormStatus(this.pendingForm.id, 'filled');
      this.displayCompletionMessageAndEmitEvent();
    }
  }
  displayCompletionMessageAndEmitEvent() {
    this.formFillSuccessMessage = 'You have successfully filled the form.';
    timer(3000).subscribe(() => {
      this.formFillSuccessMessage = '';
      if (this.isPracticeUser) {
        this.tabsService.close_tab(this.tabsService.active_tab());
      }
      this.formCompleted.emit();
    });
    this.patientIntakeFormService.reloadIntakeForms.next(1);
  }

  onPrevious() {
    this.showEnote = false;
    this.selectedStepIndex--;
    this.selectedStep = this.pendingForm.steps[this.selectedStepIndex];
    this.selected_stage_id = this.selectedStep.is_enote_step ? 'enote' : this.selectedStep.name;
    if (this.selectedStep.is_enote_step) {
      if (this.selectedStep.enote_template_id) {
        //this.setCustomInjector(this.selectedStep.enote_template_id);
        this.setSelectedTemplate(this.selectedStep.enote_template_id);
        //this.showEnote = true;
      }
    }
  }
  setCustomInjector(note_id) {
    this.custom_injector  = Injector.create([
      {provide: 'template', useValue: note_id},
      {provide: 'ui', useValue: true},
      {provide: 'stage', useValue: (this.selectedStep.status == 'pending' || this.selectedStep.status == 'skipped') ? 'create_enote' : 'drafted_enote'},
      {provide: 'source_id', useValue: this.selectedStep.id},
      {provide: 'source_type', useValue: 'IntakeStep'},
      {provide: 'patient_name', useValue: this.patient ? this.patient.name : ''},
      {provide: 'patient_id', useValue: this.patient ? this.patient.id : ''},
    ], this.injector);
  }
  setSelectedTemplate(note_id) {
    this.loaderService.show();
    this.enoteService.template = new Template();
    this.enoteService.getTemplate(note_id).then(resp => {
      this.setCustomInjector(note_id);
      this.showEnote = true;
      this.loaderService.hide();
    }).catch(error => {
      console.error(error);
      this.loaderService.hide();
    })
  }

  private getPatient(patientID) {
    this.patientService.getPatient(patientID).then(resp => {
      this.patient = new Patient().load_from_json(resp);
    });
  }

  private getTemplate(id: number) {
    this.patientIntakeFormService.getTemplate(id).then(resp => {
      this.pendingForm = new IntakeFormTemplate().load_from_json(resp);
      this.initializeFields();
    })

  }
}

